import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Some useful reference material`}</h2>
    <p>{`This page gives access to items that should help understand the figures and animations on this site and assist those who wish to implement the rotation models
on their own plate-reconstruction software. There are also links to abstracts of recent presentations. Queries?  `}<a parentName="p" {...{
        "href": "../../contact"
      }}>{`Please get in touch`}</a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../legend-to-the-anims"
        }}>{`Legend to the animations:`}</a>{` Explanation of the colours used in the figures and animations`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../latest-rotation"
        }}>{`Recent rotation poles:`}</a>{` Euler roatation parameters for the most recent reconstruction model`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../pesgb-africa-2019"
        }}>{`Reeves & Teasdale, 2019:`}</a>{` `}<em parentName="li">{`The creation of the African margins and the Mesozoic demise of Gondwana`}</em></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../nacgeo-march-2020"
        }}>{`Reeves, 2020:`}</a>{` `}<em parentName="li">{`African geology, the Bouvet mantle plume and the early opening of the Gondwana margins`}</em></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../nacgea-april-2021"
        }}>{`Reeves & Souza, 2021:`}</a>{` `}<em parentName="li">{`The lost 'continents' of the South Atlantic Ocean`}</em></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.reeves.nl/upload/nac2022-cvr.pdf"
        }}>{`Reeves, 2022:`}</a>{` `}<em parentName="li">{`A comprehensive model for the growth of the oceans that separated Gondwana using fracture-zone retracing`}</em></li>
    </ul>
    <p><em parentName="p">{`Last update: 2022 August 16`}</em></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../../gondwana"
        }}>{`Return to Gondwana main page`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      